const colors = {
  primary: "#2E4568",
  secondary: "#E0A100",

  success: "#00E2C0",
  info: "#22DAE5",
  warning: "#FF9A23",
  danger: "	#DC143C",

  black: "#000000",
  white: "#FFFFFF",
  transparent: "transparent",
  darkTransparent: "#00000066",
  whiteTransparent: "#FFFFFF88",
};

export default colors;
