import React, { Component, createElement } from "react";
import PropTypes from "prop-types";
import { isDescendant } from "./tree-data-utils";
import classnames from "./classnames";

import "react-sortable-tree/style.css";

import "./default.css";

import * as Icons from "react-icons/fa";

class NodeRendererDefault extends Component {

  render() {
    const {
      scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      buttons,
      className,
      style,
      didDrop,
      treeId,
      isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      rowDirection,
      ...otherProps
    } = this.props;
    const nodeTitle = title || node.title;
    const nullSubtitle = node.subtitle === null;
    const nodeSubtitle = nullSubtitle ? "" : subtitle || (node.subtitle.length > 100 ? `${node.subtitle.slice(0, 67)}...` : node.subtitle);
    const rowDirectionClass = rowDirection === "rtl" ? "rst__rtl" : null;

    const lineBreak = (line) => {
      if (!line) return;
      const words = line.split(' ');

      const lines = words.reduce((acc, word) => {
        const currentLine = acc[acc.length - 1];
        const newLine = currentLine + ' ' + word;

        if (newLine.length > 70) {
          acc.push(word);
        } else {
          acc[acc.length - 1] = newLine.trim();
        }

        return acc;
      }, ['']);

      return lines.join('<br/>');
    }

    const showKeywords = (keywords) => {
      if (keywords && keywords.length > 0) {
        return '</br> </br> Keywords: ' + keywords.join(', ');
      }

      return ''
    }

    let handle;
    if (typeof node.children === "function" && node.expanded) {
      // Show a loading symbol on the handle when the children are expanded
      //  and yet still defined by a function (a callback to fetch the children)
      handle = (
        <div className="rst__loadingHandle">
          <div className="rst__loadingCircle">
            {[...new Array(12)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={classnames(
                  "rst__loadingCirclePoint",
                  rowDirectionClass
                )}
              />
            ))}
          </div>
        </div>
      );
    } else {
      // Show the handle used to initiate a drag-and-dro

      if (canDrag) {
        handle = connectDragSource(<div className="rst__moveHandle" >
          {
            createElement(
              Icons[this.props.icon || 'FaLine'],
              { color: '#4297d7' },
            )
          }
        </div>, {
          dropEffect: "copy",
        });
      } else {
        handle = <div className="rst__moveHandle defaultCursor" >
          {
            createElement(
              Icons[this.props.icon || 'FaLine'],
              { color: '#4297d7' },
            )
          }
        </div>
      }
    }

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    let buttonStyle = { left: -0.5 * scaffoldBlockPxWidth };
    if (rowDirection === "rtl") {
      buttonStyle = { right: -0.5 * scaffoldBlockPxWidth };
    }

    return (
      <div style={{ height: "100%" }} {...otherProps}>
        {toggleChildrenVisibility &&
          node.children &&
          (node.children.length > 0 || typeof node.children === "function") && (
            <div>
              <button
                type="button"
                aria-label={node.expanded ? "Collapse" : "Expand"}
                className={classnames(
                  node.expanded ? "rst__collapseButton" : "rst__expandButton",
                  rowDirectionClass
                )}
                style={buttonStyle}
                onClick={() =>
                  toggleChildrenVisibility({
                    node,
                    path,
                    treeIndex,
                  })
                }
              />

              {node.expanded && !isDragging && (
                <div
                  style={{ width: scaffoldBlockPxWidth }}
                  className={classnames("rst__lineChildren", rowDirectionClass)}
                />
              )}
            </div>
          )}

        <div className={classnames("rst__rowWrapper", rowDirectionClass)} onClick={() => otherProps.onClick(node.title)}>

          {/* Set the row preview to be used during drag and drop */}
          {connectDragPreview(
            <div
              className={classnames(
                "rst__row",
                node.focused && "rst__shadow",
                isLandingPadActive && "rst__rowLandingPad",
                isLandingPadActive && !canDrop && "rst__rowCancelPad",
                isSearchMatch && "rst__rowSearchMatch",
                isSearchFocus && "rst__rowSearchFocus",
                rowDirectionClass,
                className
              )}

              style={{
                opacity: isDraggedDescendant ? 0.5 : 1,
                ...style,
              }}
            >

              {handle}

              <div
                className={classnames(
                  "rst__rowContents",
                  !canDrag && "rst__rowContentsDragDisabled",
                  rowDirectionClass
                )}
                data-tooltip-id="tooltip"
                // data-tooltip-content={node.subtitle}
                data-tooltip-html={lineBreak(node.subtitle) + showKeywords(node.keywords)}
              >
                <div className={classnames("rst__rowLabel", rowDirectionClass)}>
                  <span
                    className={classnames(
                      "rst__rowTitle",
                      node.subtitle && "rst__rowTitleWithSubtitle"
                    )}
                  >
                    {typeof nodeTitle === "function"
                      ? nodeTitle({
                        node,
                        path,
                        treeIndex,
                      })
                      : nodeTitle}
                  </span>

                  {nodeSubtitle && (
                    <span className="rst__rowSubtitle">
                      {typeof nodeSubtitle === "function"
                        ? nodeSubtitle({
                          node,
                          path,
                          treeIndex,
                        })
                        : nodeSubtitle}
                    </span>
                  )}
                </div>

                <div className="rst__rowToolbar">
                  {buttons.map((btn, index) => (
                    <div
                      key={index} // eslint-disable-line react/no-array-index-key
                      className="rst__toolbarButton"
                    >
                      {btn}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NodeRendererDefault.defaultProps = {
  isSearchMatch: false,
  isSearchFocus: false,
  canDrag: false,
  toggleChildrenVisibility: null,
  buttons: [],
  className: "",
  style: {},
  parentNode: null,
  draggedNode: null,
  canDrop: false,
  title: null,
  subtitle: null,
  rowDirection: "ltr",
};

NodeRendererDefault.propTypes = {
  node: PropTypes.shape({}).isRequired,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  path: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  isSearchMatch: PropTypes.bool,
  isSearchFocus: PropTypes.bool,
  canDrag: PropTypes.bool,
  scaffoldBlockPxWidth: PropTypes.number.isRequired,
  toggleChildrenVisibility: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  style: PropTypes.shape({}),

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  isDragging: PropTypes.bool.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  // Drop target
  isOver: PropTypes.bool.isRequired,
  canDrop: PropTypes.bool,

  // rtl support
  rowDirection: PropTypes.string,
};

export default NodeRendererDefault;
