import { useCallback, useEffect, useState } from "react";

// Libs
import { GridSortDirection } from "@mui/x-data-grid";

// Store
import { store } from "store";

// API
import {
  getBranchListAPI,
  getDocIdListAPI,
  getDashboardAPI,
  getStageListAPI,
  getStatusFilterAPI,
  getStatusListAPI,
  getTopicListAPI,
} from "services/api/endpoints/dashboard";
import {
  changeAnnotationGoldAPI,
  changeAnnotationSilverAPI,
  changeAnnotationStatusAPI,
  getAnnotationStatusAPI,
} from "services/api/endpoints/document";

// Types
import { DocumentStatusList } from "types/document";

const useDashboard = () => {
  const {
    addRows,
    setTotal,
    setTotalNotFiltered,
    setOffset,
    clear,
    rows,
    filters,
    setFilters,
  } = store.dashboardStore;
  const { setUser } = store.userStore;

  const [documentStatusList, setDocumentStatusList] = useState<
    DocumentStatusList[] | undefined
  >(undefined);
  const [selectedDocument, setSelectedDocument] = useState<
    | {
        _id: number;
        status: string;
        branch: string;
        dataset: string;
        document: string;
        topic: string;
      }
    | undefined
  >();

  const [docIdList, setDocIdList] = useState<string[]>(["None"]);
  const [selectedDocIdList, setSelectedDocIdList] = useState<
    string | undefined
  >(filters.id);

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<
    string | undefined
  >(filters.status_filter);

  const [statusList, setStatusList] = useState<string[]>([]);
  const [selectedStatusList, setSelectedStatusList] = useState<
    string | undefined
  >(filters.status);

  const [stageList, setStageList] = useState<string[]>([]);
  const [selectedStageList, setSelectedStageList] = useState<
    string | undefined
  >(filters.stage);

  const [topicList, setTopicList] = useState<string[]>([]);
  const [selectedTopicList, setSelectedTopicList] = useState<
    string | undefined
  >(filters.topic);

  const [branchList, setBranchList] = useState<string[]>([]);
  const [selectedBranchList, setSelectedBranchList] = useState<
    string | undefined
  >(filters.branch);

  const [sort, setSort] = useState<{ sort: string; order: GridSortDirection }>({
    sort: "id",
    order: "asc",
  });

  const [adjudicator, setAdjudicator] = useState<
    { options: string[]; status: string; title: string } | undefined
  >();

  const [toast, setToast] = useState<{
    isOpen: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  }>({ isOpen: false, message: "", severity: "error" });

  const getRows = useCallback(async () => {
    try {
      const {
        data: { rows, total, totalNotFiltered },
      } = await getDashboardAPI({
        doc: selectedDocIdList !== "None" ? selectedDocIdList : undefined,
        status_filter:
          selectedStatusFilter !== "None" ? selectedStatusFilter : undefined,
        status: selectedStatusList !== "None" ? selectedStatusList : undefined,
        stage: selectedStageList !== "None" ? selectedStageList : undefined,
        topic: selectedTopicList !== "None" ? selectedTopicList : undefined,
        branch: selectedBranchList !== "None" ? selectedBranchList : undefined,
        sort,
      });

      addRows({ rows });
      setTotal({ total });
      setTotalNotFiltered({ totalNotFiltered });
      setOffset();
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, [
    selectedDocIdList,
    addRows,
    setOffset,
    setTotal,
    setTotalNotFiltered,
    selectedStatusFilter,
    selectedStatusList,
    selectedStageList,
    selectedTopicList,
    selectedBranchList,
    sort,
  ]);

  const clearFilters = useCallback(() => {
    setSelectedDocIdList(undefined);
    setSelectedStatusFilter(undefined);
    setSelectedStatusList(undefined);
    setSelectedStageList(undefined);
    setSelectedTopicList(undefined);
    setSelectedBranchList(undefined);
  }, []);

  const getStatusFilter = useCallback(async () => {
    try {
      const { data } = await getStatusFilterAPI();

      const formattedStatusFilter: string[] = [];

      Object.keys(data).forEach((statusFilter, index) => {
        formattedStatusFilter.push(statusFilter);
      });

      formattedStatusFilter.push("None");

      setStatusFilter(formattedStatusFilter);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const getStatusList = useCallback(async () => {
    try {
      const { data } = await getStatusListAPI();

      const formattedStatusList: string[] = [];

      Object.keys(data).forEach((statusFilter, index) => {
        formattedStatusList.push(statusFilter);
      });

      formattedStatusList.push("None");

      setStatusList(formattedStatusList);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const getStageList = useCallback(async () => {
    try {
      const { data } = await getStageListAPI();

      const formattedStageList: string[] = [];

      Object.keys(data).forEach((statusFilter, index) => {
        formattedStageList.push(String(statusFilter));
      });

      formattedStageList.push("None");

      setStageList(formattedStageList);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const getTopicList = useCallback(async () => {
    try {
      const { data } = await getTopicListAPI();

      const formattedTopicList: string[] = [];

      Object.keys(data).forEach((topic, index) => {
        formattedTopicList.push(topic);
      });

      formattedTopicList.push("None");

      setTopicList(formattedTopicList);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const getBranchList = useCallback(async () => {
    try {
      const { data } = await getBranchListAPI();

      const formattedBranchList: string[] = [];

      Object.keys(data).forEach((branch, index) => {
        formattedBranchList.push(branch);
      });

      formattedBranchList.push("None");

      setBranchList(formattedBranchList);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const getDocIdList = useCallback(async () => {
    try {
      const { data } = await getDocIdListAPI();

      const formattedDocIdList: string[] = [];

      formattedDocIdList.push("None");

      Object.keys(data).forEach((id, index) => {
        formattedDocIdList.push(id);
      });

      setDocIdList(formattedDocIdList);
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, []);

  const requestDocumentStatus = useCallback(
    async ({
      _id,
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      _id: number;
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        const response = await getAnnotationStatusAPI({
          dataset,
          branch,
          topic,
          document,
        });

        const { data } = response;

        const documentStatusListAux: DocumentStatusList[] = [];

        setSelectedDocument({
          _id,
          dataset,
          branch,
          topic,
          document,
          status: data.status,
        });

        Object.values(data.status_list).forEach((value, index) => {
          documentStatusListAux.push(value);
        });

        setDocumentStatusList(documentStatusListAux);
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    []
  );

  const changeDocumentStatus = useCallback(
    async ({
      status,
      branch,
      dataset,
      document,
      topic,
      adjudicator_name,
    }: {
      status: string;
      branch: string;
      dataset: string;
      document: string;
      topic: string;
      adjudicator_name?: string;
    }) => {
      if (branch && dataset && document && topic) {
        try {
          const { data } = await changeAnnotationStatusAPI({
            dataset,
            branch,
            topic,
            document,
            status,
            adjudicator_name,
          });

          if (data.status) {
            const _id = selectedDocument?._id;

            if (_id) {
              setSelectedDocument({
                _id,
                dataset,
                branch,
                topic,
                document,
                status,
              });

              const rowIndex = rows.findIndex((row) => row._id === _id);

              rows[rowIndex].status = status;
            }
          } else {
            setToast({
              isOpen: true,
              message: data.message,
              severity: "info",
            });
          }
        } catch (error: any) {
          setToast({
            isOpen: true,
            message: error?.response?.data?.detail,
            severity: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          message: "Inform all params",
          severity: "error",
        });
      }
    },
    [selectedDocument, rows]
  );

  const changeAnnotationToSilver = useCallback(
    async ({
      _id,
      topic,
      branch,
      dataset,
      document,
    }: {
      _id: number;
      topic: string;
      branch: string;
      dataset: string;
      document: string;
    }) => {
      if (branch && dataset && document && topic) {
        try {
          const {
            data: { allowed_base_gold_ids },
          } = await changeAnnotationSilverAPI({
            dataset,
            branch,
            topic,
            document,
          });

          const rowIndex = rows.findIndex((row) => row._id === _id);

          rows[rowIndex].set_silver_allowed = false;

          if (allowed_base_gold_ids.length === 2) {
            const docIndex1 = rows.findIndex(
              (row) => row._id === allowed_base_gold_ids[0]
            );
            const docIndex2 = rows.findIndex(
              (row) => row._id === allowed_base_gold_ids[1]
            );

            if (docIndex1 !== undefined) {
              rows[docIndex1].set_base_gold_allowed = true;
            }

            if (docIndex2 !== undefined) {
              rows[docIndex2].set_base_gold_allowed = true;
            }
          }
        } catch (error: any) {
          setToast({
            isOpen: true,
            message: error?.response?.data?.detail,
            severity: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          message: "Inform all params",
          severity: "error",
        });
      }
    },
    [rows]
  );

  const changeAnnotationToGold = useCallback(
    async ({
      _id,
      topic,
      branch,
      dataset,
      document,
    }: {
      _id: number;
      topic: string;
      branch: string;
      dataset: string;
      document: string;
    }) => {
      if (branch && dataset && document && topic) {
        try {
          const {
            data: { not_allowed_base_gold_ids },
          } = await changeAnnotationGoldAPI({
            dataset,
            branch,
            topic,
            document,
          });

          const rowIndex = rows.findIndex((row) => row._id === _id);

          rows[rowIndex].set_base_gold_allowed = false;

          const secondID = not_allowed_base_gold_ids[0];

          const secondDocIndex = rows.findIndex((row) => row._id === secondID);

          if (secondDocIndex !== undefined) {
            rows[secondDocIndex].set_base_gold_allowed = false;
          }
        } catch (error: any) {
          setToast({
            isOpen: true,
            message: error?.response?.data?.detail,
            severity: "error",
          });
        }
      } else {
        setToast({
          isOpen: true,
          message: "Inform all params",
          severity: "error",
        });
      }
    },
    [rows]
  );

  useEffect(() => {
    clear();
    getRows();

    // if (statusFilter.length === 0)
    getStatusFilter();
    // if (statusList.length === 0)
    getStatusList();
    // if (stageList.length === 0)
    getStageList();
    // if(topicList.length === 0)
    getTopicList();
    // if(branchList.length === 0)
    getBranchList();

    getDocIdList();
  }, [
    selectedDocIdList,
    getRows,
    clear,
    getStatusFilter,
    getStatusList,
    getStageList,
    getTopicList,
    getBranchList,
    getDocIdList,
    selectedStatusFilter,
    selectedStatusList,
    selectedTopicList,
    selectedBranchList,
    sort,
  ]);

  useEffect(() => {
    setFilters({
      id: selectedDocIdList,
      status_filter: selectedStatusFilter,
      status: selectedStatusList,
      stage: selectedStageList,
      topic: selectedTopicList,
      branch: selectedBranchList,
    });
  }, [
    setFilters,
    selectedDocIdList,
    selectedStatusFilter,
    selectedBranchList,
    selectedTopicList,
    selectedStatusList,
    selectedStageList,
  ]);

  return {
    clearFilters,
    docIdList,
    setDocIdList,
    selectedDocIdList,
    setSelectedDocIdList,
    getRows,
    statusFilter,
    requestDocumentStatus,
    changeDocumentStatus,
    changeAnnotationToSilver,
    changeAnnotationToGold,
    documentStatusList,
    selectedDocument,
    selectedStatusFilter,
    setSelectedStatusFilter,
    statusList,
    stageList,
    selectedStatusList,
    setSelectedStatusList,
    selectedStageList,
    setSelectedStageList,
    topicList,
    selectedTopicList,
    setSelectedTopicList,
    branchList,
    selectedBranchList,
    setSelectedBranchList,
    setUser,
    setSort,
    adjudicator,
    setAdjudicator,
    toast,
    setToast,
  };
};

export default useDashboard;
