import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Libs
import { useSearchParams } from "react-router-dom";

// Components

// Styles
import "styles/Home.css";

// API
import { getDocumentJSONOutlinerAPI } from "services/api/endpoints/document";

// Services
import webSocket from "services/webSocket";

// Icons

// Colors

// Types
import { IOutlinerData } from "./types";

const useArgumentOutliner = () => {
  const [searchParams] = useSearchParams();

  const [tree, setTree] = useState<IOutlinerData[]>([]);
  const [grouped, setGrouped] = useState<
    { default: boolean; visible: boolean } | undefined
  >();
  const [byTopic, setByTopic] = useState<
    { default: boolean; visible: boolean } | undefined
  >();
  const [summarized, setSummarized] = useState<
    { default: boolean; visible: boolean } | undefined
  >();
  const [connnectionStatus, setConnnectionStatus] = useState(false);

  const [showDocumentInfo, setShowDocumentInfo] = useState(false);

  const [docInfo, setDocInfo] = useState<
    { gender: string; imgsBase64: string[]; url: string } | undefined
  >(undefined);

  const [toast, setToast] = useState<{
    isOpen: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  }>({ isOpen: false, message: "", severity: "error" });

  const wsRef = useRef<WebSocket | undefined>(undefined);

  const [backgroundColorNodes, setBackgroundColorNodes] = useState<{
    [label: string]: string;
  }>({});

  const getURLParams = useMemo(() => {
    const branch = searchParams.get("branch");
    const dataset = searchParams.get("dataset");
    const document = searchParams.get("document");
    const timestamp = searchParams.get("timestamp");
    const topic = searchParams.get("topic");

    return {
      branch: branch,
      dataset: dataset,
      document: document,
      timestamp: timestamp,
      topic: topic,
    };
  }, [searchParams]);

  const requestDocumentMetaData = useCallback(
    async ({
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        if (!grouped && !summarized && !byTopic) {
          const {
            data: { options, properties },
          } = await getDocumentJSONOutlinerAPI({
            dataset,
            branch,
            topic,
            document,
            timestamp,
            grouped: true,
            byTopic: true,
            summarized: true,
            shorted: true,
            only_metadata: true,
          });

          const stageConfig = options.formats[properties.stage];

          setSummarized(stageConfig.summarized);
          setGrouped(stageConfig.grouped);
          setByTopic(stageConfig.topic);

          setBackgroundColorNodes({
            ...options.backgroundColorNodes,
            SENTENCETOLONG: "#FFFF00",
          });
        }
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    [byTopic, grouped, summarized]
  );

  const requestDocument = useCallback(
    async ({
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        if (grouped && summarized && byTopic) {
          const {
            data: { nodes, options, documentInfo },
          } = await getDocumentJSONOutlinerAPI({
            dataset,
            branch,
            topic,
            document,
            timestamp,
            grouped: grouped.default,
            byTopic: byTopic.default,
            summarized: summarized.default,
            shorted: true,
            only_metadata: false,
          });

          addIconToNodes({ nodes: [nodes], icons: options.icons });

          setTree([nodes]);
          setDocInfo(documentInfo);
        }
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [grouped, summarized, byTopic]
  );

  const addIconToNodes = useCallback(
    ({
      nodes,
      icons,
    }: {
      nodes?: IOutlinerData[];
      icons: { [label: string]: string };
    }): IOutlinerData | undefined => {
      let node = undefined;

      if (nodes)
        for (let i = 0; i < nodes.length; i++) {
          addIconToNodes({ nodes: nodes[i].children, icons });

          nodes[i].icon = icons[nodes[i].label];
        }

      return node;
    },
    []
  );

  const refresh = useCallback(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      requestDocument({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Inform all params",
        severity: "error",
      });
    }
  }, [requestDocument, getURLParams]);

  const disconnect = useCallback(() => {
    wsRef.current?.close();
  }, []);

  const connect = useCallback(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      const ws = webSocket({
        dataset,
        branch,
        topic,
        document,
        timestamp: Number(timestamp),
      });

      wsRef.current = ws;

      ws.onmessage = function (event) {
        refresh();
      };

      ws.onopen = function (event) {
        setConnnectionStatus(true);
      };

      ws.onerror = function (event) {
        setConnnectionStatus(false);
        setToast({
          isOpen: true,
          message: "WebSocket Connection error.",
          severity: "error",
        });
      };

      ws.onclose = function (event) {
        setConnnectionStatus(false);
      };
    }
  }, [refresh, getURLParams]);

  useEffect(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      connect();

      requestDocumentMetaData({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });

      requestDocument({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Inform all params",
        severity: "error",
      });
    }
  }, [
    requestDocumentMetaData,
    requestDocument,
    grouped,
    byTopic,
    summarized,
    getURLParams,
    connect,
  ]);

  const getNodeById = useCallback(
    ({
      id,
      nodes,
    }: {
      id: string;
      nodes?: IOutlinerData[];
    }): IOutlinerData | undefined => {
      let node = undefined;

      if (nodes)
        for (let i = 0; i < nodes.length; i++) {
          node = getNodeById({ id, nodes: nodes[i].children });

          if (node) {
            return node;
          } else if (nodes[i].id === id) {
            return nodes[i];
          }
        }

      return node;
    },
    []
  );

  const applyTopic = useCallback(
    ({ id }: { id: string }) => {
      getNodeById({ id, nodes: tree });
    },
    [getNodeById, tree]
  );

  return {
    tree,
    grouped,
    byTopic,
    summarized,
    setTree,
    setGrouped,
    setByTopic,
    setSummarized,
    backgroundColorNodes,
    getURLParams,
    connnectionStatus,
    connect,
    disconnect,
    refresh,
    toast,
    setToast,
    applyTopic,
    showDocumentInfo,
    setShowDocumentInfo,
    docInfo,
  };
};

export default useArgumentOutliner;
