// Libs
import { observer } from "mobx-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Screens
import ArgumentMap from "screens/ArgumentMap";
import Dashboard from "screens/Dashboard";
import SignIn from "screens/SignIn";
import ArgumentOutliner from "screens/ArgumentOutliner";

// Store
import { store } from "store";
import ArgumentOutlinerStage3 from "screens/ArgumentOutlinerStage3";

// Contexts
import IdleTimer from "contexts/IdleTimer";

const authRouter = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
  },

  {
    path: "/map",
    element: <ArgumentMap />,
  },

  {
    path: "/dashboard",
    element: <Dashboard />,
  },

  {
    path: "/outliner",
    element: <ArgumentOutliner />,
  },

  {
    path: "/treemerger",
    element: <ArgumentOutlinerStage3 />,
  },
]);

const unauthRouter = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },

  {
    path: "*",
    element: <SignIn />,
  },
]);

const Routes = () => {
  const { user } = store.userStore;

  // const tracker = new Tracker();

  // tracker.on("*", (event: any) => console.log(event));

  if (user)
    return (
      <IdleTimer>
        <RouterProvider router={authRouter} />
      </IdleTimer>
    );
  else return <RouterProvider router={unauthRouter} />;
};

export default observer(Routes);
