import styled from "styled-components";
import colors from "styles/colors";

export const Container = styled.div<{ background: string }>`
  flex: 1;
  height: 100%;
  background: ${(props) => props.background ?? colors.white};
  padding: 10px;
`;

export const Title = styled.strong<{ color: string }>`
  font-size: 16px;
  color: ${(props) => props.color ?? colors.white};
`;

export const Description = styled.p<{ color: string }>`
  font-size: 12px;
  color: ${(props) => props.color ?? colors.white};
  margin: 0;
  margin-top: 10px;
`;
