import { ReactNode, useCallback, useEffect, useState } from "react";

// Libs
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useIdleTimer } from "react-idle-timer";

// Store
import { store } from "store";
import { pingRequestAPI } from "services/api/endpoints/user";

// env
const { TIMEOUT, TIME_TO_SHOW_MODAL_IN_SECONDS, PING_TIME_IN_SECONDS } =
  process.env;

interface IIdleTimer {
  children: ReactNode;
}

const IdleTimer = ({ children }: IIdleTimer) => {
  const { setUser } = store.userStore;
  const [remainingTime, setRemainingTime] = useState(TIMEOUT / 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onIdle = () => {
    clearInterval(timeout());
    setUser();
  };

  const onActive = () => {
    timeout();
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: TIMEOUT,
    events: ["click", "mousemove", "keypress"],
  });

  const timeout = useCallback(() => {
    const interval = setInterval(async () => {
      try {
        const _remainingTime = Math.ceil(getRemainingTime() / 1000);

        setRemainingTime(_remainingTime);

        if (
          _remainingTime !== TIMEOUT / 1000 &&
          _remainingTime % PING_TIME_IN_SECONDS === 0 &&
          _remainingTime !== 0
        ) {
          await pingRequestAPI();
        }

        if (_remainingTime === TIMEOUT) {
          setIsModalOpen(false);
        } else if (_remainingTime === TIME_TO_SHOW_MODAL_IN_SECONDS) {
          setIsModalOpen(true);
        } else if (_remainingTime === 0) {
          clearInterval(interval);
        }
      } catch (error) {
        console.log({ error });
      }
    }, 1000);

    return interval;
  }, [getRemainingTime]);

  useEffect(() => {
    timeout();
    return () => clearInterval(timeout());
  }, [timeout]);

  return (
    <>
      {children}

      <Dialog
        open={isModalOpen}
        onClose={setIsModalOpen}
        aria-labelledby="idle-timer-alert-dialog-title"
        aria-describedby="idle-timer-alert-dialog-description"
      >
        <DialogTitle id="idle-timer-alert-dialog-title">
          Você ainda está aqui?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="idle-timer-alert-dialog-description">
            Movimente o mouse ou pressione alguma tecla para continuar ativo.
            Tempo restante {remainingTime} segundos...
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IdleTimer;
