import { useState } from "react";

// Libs
import ReactFlow, { ConnectionLineType, Edge, Node } from "react-flow-renderer";

// View Model
import useArgumentMap from "./view.model";

// Components
import {
  Button,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Checkbox,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

// Colors
import colors from "styles/colors";

// Types
import { Label } from "types/document";

const ArgumentMap = () => {
  const [selectedNode, setSelectedNode] = useState<Node | undefined>(undefined);
  const [, setSelectedEdge] = useState<Edge | undefined>(undefined);
  const [showMenuOptions, setShowMenuOptions] = useState(false);
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [mousePosition] = useState<{ x: number; y: number } | undefined>(
    undefined
  );
  const {
    nodes,
    edges,
    grouped,
    setGrouped,
    summarized,
    setSummarized,
    onEdgesChange,
    onNodesChange,
    onConnect,
    connnectionStatus,
    editNodeLabel,
    connect,
    disconnect,
    nodeOptions,
    refresh,
    toast,
    setToast,
  } = useArgumentMap();

  const onClickNode = (
    event: React.MouseEvent<Element, MouseEvent>,
    node: Node
  ) => {
    setSelectedNode(node);
    setSelectedEdge(undefined);
  };

  const onNodeContextMenu = (
    event: React.MouseEvent<Element, MouseEvent>,
    node: Node
  ) => {
    // event.preventDefault();
    // setMousePosition({ x: event.clientX, y: event.clientY });
    // setSelectedNode(node);
    // setSelectedEdge(undefined);
    // setShowMenuOptions(true);
  };

  const onClickEdge = (
    event: React.MouseEvent<Element, MouseEvent>,
    edge: Edge
  ) => {
    setSelectedEdge(edge);
    setSelectedNode(undefined);
  };

  const clearSelection = () => {
    setSelectedNode(undefined);
    setSelectedEdge(undefined);
  };

  return (
    <div className="floatingedges">
      <Box sx={{ flexGrow: 1, flex: 1 }}>
        <AppBar position="static" style={{ background: colors.white }}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              {/* <MenuIcon color="primary" /> */}
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ color: colors.black }}
            >
              ArgMap
            </Typography>

            <FormControlLabel
              control={<Checkbox checked={grouped} />}
              label="Grouped"
              onChange={(event, value) => setGrouped(value)}
              style={{ color: colors.black }}
            />
            <FormControlLabel
              control={<Checkbox checked={summarized} />}
              label="Summarized"
              onChange={(event, value) => {
                setSummarized(value);
              }}
              style={{ color: colors.black }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={connnectionStatus}
                  onChange={connnectionStatus ? disconnect : connect}
                />
              }
              label={"Auto refresh"}
              style={{ color: colors.black }}
            />

            <Button
              color="inherit"
              onClick={() => refresh()}
              style={{ color: colors.black }}
            >
              Refresh
            </Button>
          </Toolbar>
        </AppBar>

        <div className="floatingedges">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            connectionLineType={ConnectionLineType.SmoothStep}
            fitView
            minZoom={0.08}
            maxZoom={3}
            onNodeClick={onClickNode}
            onNodeDrag={onClickNode}
            onNodeContextMenu={onNodeContextMenu}
            onEdgeClick={onClickEdge}
            onPaneClick={clearSelection}
          />
        </div>
        {mousePosition && (
          <Popover
            open={showMenuOptions}
            onClose={() => setShowMenuOptions(false)}
            anchorOrigin={{
              vertical: mousePosition?.y,
              horizontal: mousePosition?.x,
            }}
          >
            <List>
              {nodeOptions.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    if (selectedNode) {
                      if (item.action) {
                        item.action(selectedNode);
                      } else {
                        setShowEditOptions(true);
                      }
                    }

                    setShowMenuOptions(false);
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon style={{ minWidth: 30 }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        )}

        {mousePosition && (
          <Popover
            open={showEditOptions}
            onClose={() => setShowMenuOptions(false)}
            anchorOrigin={{
              vertical: mousePosition?.y,
              horizontal: mousePosition?.x,
            }}
          >
            <List>
              {Object.values(Label).map((item: Label, index: number) => {
                return (
                  <ListItem
                    key={index}
                    disablePadding
                    onClick={() => {
                      if (selectedNode) {
                        editNodeLabel(selectedNode, item);
                      }

                      setShowEditOptions(false);
                    }}
                  >
                    <ListItemButton>
                      <ListItemText primary={item} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        )}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.isOpen}
        onClose={() =>
          setToast({ isOpen: false, message: "", severity: "error" })
        }
      >
        <Alert
          onClose={() =>
            setToast({ isOpen: false, message: "", severity: "error" })
          }
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ArgumentMap;
