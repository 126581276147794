import React from "react";

// Libs
import { Grid, Paper, TextField, Button, Snackbar, Alert } from "@mui/material";

// Store

// View Model
import useSignIn from "./view.model";

const paperStyle = {
  padding: 20,
  height: 230,
  width: 280,
};
const btnstyle = { margin: "8px 0" };

const SignIn: React.FC = () => {
  const {
    userName,
    setUserName,
    password,
    setPassword,
    login,
    toast,
    setToast,
  } = useSignIn();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", paddingBottom: 300 }}
    >
      <Paper elevation={10} style={paperStyle}>
        <TextField
          label="Username"
          placeholder="Enter username"
          variant="outlined"
          fullWidth
          required
          style={{ paddingBottom: 20 }}
          value={userName}
          onChange={(event) => {
            const { value } = event.target;
            setUserName(value);
          }}
        />
        <TextField
          label="Password"
          placeholder="Enter password"
          type="password"
          variant="outlined"
          fullWidth
          required
          value={password}
          onChange={(event) => {
            const { value } = event.target;
            setPassword(value);
          }}
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          style={btnstyle}
          fullWidth
          onClick={login}
        >
          Sign in
        </Button>
      </Paper>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.isOpen}
        onClose={() =>
          setToast({ isOpen: false, message: "", severity: "error" })
        }
      >
        <Alert
          onClose={() =>
            setToast({ isOpen: false, message: "", severity: "error" })
          }
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default SignIn;
