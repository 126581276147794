import React, { useState } from "react";

// Libs
import {
  DataGrid,
  GridApi,
  GridSortModel,
  GridToolbar,
} from "@mui/x-data-grid";
import { observer } from "mobx-react-lite";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import PublishedWithChangesSharpIcon from "@mui/icons-material/PublishedWithChangesSharp";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";

// Store
import { store } from "store";

// View Model
import useDashboard from "./view.model";
import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import colors from "styles/colors";

// Images
import ArgmapSVG from "./../../assets/svg/argmap.svg";
import BratSVG from "./../../assets/svg/brat.svg";
import { IRow } from "store/dashboard/types";

const Dashboard: React.FC = () => {
  const { rows } = store.dashboardStore;
  const [statusDocumentDialog, setStatusDocumentDialog] = useState(false);

  const {
    clearFilters,
    docIdList,
    selectedDocIdList,
    setSelectedDocIdList,
    getRows,
    statusFilter,
    selectedStatusFilter,
    setSelectedStatusFilter,
    statusList,
    stageList,
    selectedStatusList,
    setSelectedStatusList,
    selectedStageList,
    setSelectedStageList,
    topicList,
    selectedTopicList,
    setSelectedTopicList,
    branchList,
    selectedBranchList,
    setSelectedBranchList,
    requestDocumentStatus,
    changeDocumentStatus,
    changeAnnotationToSilver,
    changeAnnotationToGold,
    documentStatusList,
    selectedDocument,
    setUser,
    setSort,
    adjudicator,
    setAdjudicator,
    toast,
    setToast,
  } = useDashboard();

  const columns = [
    {
      editable: false,
      field: "_id",
      headerName: "ID",
      width: 80,
    },

    {
      editable: false,
      field: "topic",
      headerName: "Topic",
      width: 150,
    },

    {
      editable: false,
      headerName: "Doc",
      width: 150,
      field: "doc",
      valueGetter: ({ row }: { row: IRow }) => {
        return `${row.doc}.${row.type}`;
      },
      // renderCell: (client: IRow) => {
      //   return `${client.doc}.${client.type}`;
      // },
    },

    {
      editable: false,
      field: "start_date",
      headerName: "Start Date",
      width: 120,
    },

    {
      editable: false,
      field: "due_date",
      headerName: "Due Date",
      width: 120,
    },

    {
      editable: false,
      field: "status_filter",
      headerName: "Status Filter",
      width: 150,
    },

    {
      editable: false,
      field: "status",
      headerName: "Status",
      width: 100,
    },

    {
      editable: false,
      field: "stage",
      headerName: "Stage",
      width: 50,
    },

    {
      editable: false,
      field: "branch",
      headerName: "Branch",
      width: 180,
    },

    {
      editable: false,
      field: "gold_agreement",
      headerName: "Gold Agreement",
      width: 200,
    },

    {
      editable: false,
      field: "silver_agreement",
      headerName: "Silver Agreement",
      width: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 160,
      renderCell: (params: any) => {
        const api: GridApi = params.api;
        const thisRow: any = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach((c, i) => {
            thisRow[c.field] = params.getValue(params.id, c.field);
          });

        const dataset = "argmap";
        const { branch, topic, doc, _id } = thisRow;

        const type = rows.find((row) => row._id === _id)?.type;
        const _doc = doc.replace(`.${type}`, "");

        return (
          <>
            {/* <a
              href={`${process.env.REACT_APP_EXTERNAL_URL}brat_client/index.xhtml#/${dataset}/${branch}/${topic}/${doc}`}
              target="_blank"
              rel="noreferrer"
            >
              <InsertDriveFileSharpIcon color="primary" />
            </a> */}

            {type === "arg" && (
              <Tooltip title="Open document">
                <a
                  href={`/treemerger?dataset=${dataset}&branch=${branch}&&topic=${topic}&document=${_doc}&timestamp=1`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={ArgmapSVG}
                    alt="Argmap logo"
                    width={25}
                    height={25}
                    style={{ marginLeft: 5, marginRight: 5 }}
                  />
                </a>
              </Tooltip>
            )}

            {type === "ann" && (
              <Tooltip title="Open document">
                <a
                  href={`/outliner?dataset=${dataset}&branch=${branch}&&topic=${topic}&document=${_doc}&timestamp=1`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={BratSVG} alt="Brat logo" width={25} height={25} />
                </a>
              </Tooltip>
            )}

            <a
              href={`/map?dataset=${dataset}&branch=${branch}&&topic=${topic}&document=${_doc}&timestamp=1`}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="Show ArgumentMap">
                <AccountTreeSharpIcon color="primary" />
              </Tooltip>
            </a>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              href="#"
              onClick={() => {
                requestDocumentStatus({
                  _id,
                  branch,
                  dataset,
                  document: _doc,
                  timestamp: 1,
                  topic,
                });
                setStatusDocumentDialog(true);
              }}
            >
              <Tooltip title="Change status">
                <PublishedWithChangesSharpIcon color="primary" />
              </Tooltip>
            </a>

            {rows.find((r) => r._id === _id)?.set_silver_allowed && (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <a
                href="#"
                onClick={() => {
                  changeAnnotationToSilver({
                    _id,
                    branch,
                    dataset,
                    document: _doc,
                    topic,
                  });
                }}
              >
                <MilitaryTechIcon style={{ color: "silver" }} />
              </a>
            )}

            {rows.find((r) => r._id === _id)?.set_base_gold_allowed && (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <a
                href="#"
                onClick={() => {
                  changeAnnotationToGold({
                    _id,
                    branch,
                    dataset,
                    document: _doc,
                    topic,
                  });
                }}
              >
                <MilitaryTechIcon style={{ color: "gold" }} />
              </a>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Doc ID</InputLabel>
        <Select
          value={selectedDocIdList || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedDocIdList(value);
          }}
        >
          {docIdList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Status Filter</InputLabel>
        <Select
          value={selectedStatusFilter || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedStatusFilter(value);
          }}
        >
          {statusFilter.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Stage List</InputLabel>
        <Select
          value={selectedStageList || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedStageList(value);
          }}
        >
          {stageList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Status List</InputLabel>
        <Select
          value={selectedStatusList || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedStatusList(value);
          }}
        >
          {statusList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Topic List</InputLabel>
        <Select
          value={selectedTopicList || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedTopicList(value);
          }}
        >
          {topicList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <InputLabel style={{ color: colors.black }}>Branch List</InputLabel>
        <Select
          value={selectedBranchList || ""}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            setSelectedBranchList(value);
          }}
        >
          {branchList.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <Button variant="contained" onClick={clearFilters}>
          Clear Filters
        </Button>
      </FormControl>

      <FormControl style={{ width: 180 }}>
        <Button variant="contained" onClick={() => setUser(undefined)}>
          LogOut
        </Button>
      </FormControl>

      <DataGrid
        autoHeight
        disableColumnFilter
        sortingMode="server"
        onSortModelChange={(sortModel: GridSortModel) => {
          const _sort = sortModel[0];
          setSort({ sort: _sort?.field || "id", order: _sort?.sort || "asc" });
        }}
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        pageSize={10}
        components={{ Toolbar: GridToolbar }}
        onPageChange={() => getRows()}
      />

      <Dialog
        open={statusDocumentDialog}
        onClose={() => setStatusDocumentDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Change document annotation status
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ width: 250 }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: colors.black }}
            >
              Annotation Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDocument?.status}
              label="Annotation Status"
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                const status = documentStatusList?.find(
                  (obj) => obj.title === value
                );

                if (selectedDocument && !status?.requiredOptions) {
                  changeDocumentStatus({
                    status: value,
                    branch: selectedDocument.branch,
                    dataset: selectedDocument.dataset,
                    document: selectedDocument.document,
                    topic: selectedDocument.topic,
                  });

                  setStatusDocumentDialog(false);
                } else if (
                  status?.requiredOptions?.key === "adjudicator_name"
                ) {
                  const _adjudicator = status.requiredOptions;
                  setAdjudicator({
                    options: _adjudicator.options,
                    title: _adjudicator.title,
                    status: value,
                  });
                  setStatusDocumentDialog(false);
                }
              }}
            >
              {documentStatusList?.map((document, index) => (
                <MenuItem key={index} value={document.title}>
                  {document.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>

      <Dialog
        open={adjudicator !== undefined}
        onClose={() => setAdjudicator(undefined)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{adjudicator?.title}</DialogTitle>
        <DialogContent>
          <FormControl style={{ width: 250 }}>
            <InputLabel
              id="demo-simple-select-label"
              style={{ color: colors.black }}
            >
              Adjudicator
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedDocument?.status}
              label="Annotation Status"
              onChange={(event) => {
                const {
                  target: { value },
                } = event;

                if (selectedDocument) {
                  changeDocumentStatus({
                    status: adjudicator?.status || "",
                    branch: selectedDocument.branch,
                    dataset: selectedDocument.dataset,
                    document: selectedDocument.document,
                    topic: selectedDocument.topic,
                    adjudicator_name: value,
                  });

                  setAdjudicator(undefined);
                }
              }}
            >
              {adjudicator?.options?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.isOpen}
        onClose={() =>
          setToast({ isOpen: false, message: "", severity: "error" })
        }
      >
        <Alert
          onClose={() =>
            setToast({ isOpen: false, message: "", severity: "error" })
          }
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default observer(Dashboard);
