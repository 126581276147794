import { observable, action, makeAutoObservable } from "mobx";

// Types
import { IRow, IFilter } from "./types";
import { persist } from "mobx-persist";

export default class DashboardStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  rows: IRow[] = [];

  @observable
  @persist("object")
  filters: IFilter = {};

  limit: number = 11;
  offset: number = 0;
  order: "desc" | "asc" = "desc";
  short: string = "";
  total: number = 0;
  totalNotFiltered: number = 0;

  @action
  addRows = ({ rows }: { rows: IRow[] }) => {
    this.rows = [...this.rows, ...rows];
  };

  @action
  setTotal = ({ total }: { total: number }) => {
    this.total = total;
  };

  @action
  setTotalNotFiltered = ({
    totalNotFiltered,
  }: {
    totalNotFiltered: number;
  }) => {
    this.totalNotFiltered = totalNotFiltered;
  };

  @action
  setOffset = () => {
    this.offset = this.offset + this.limit;
  };

  @action
  clear = () => {
    this.rows = [];
    this.limit = 11;
    this.offset = 0;
    this.order = "desc";
    this.short = "";
    this.total = 0;
    this.totalNotFiltered = 0;
  };

  @action
  setFilters = (filters: IFilter) => {
    this.filters = filters;
  };
}
