enum Label {
  ClaimAgainst = "ClaimAgainst",
  ClaimFavor = "ClaimFavor",
  Definition = "Definition",
  Event = "Event",
  Evidence = "Evidence",
  QuantData = "QuantData",
  SentenceToLong = "SentenceToLong",
  SupportedClaimAgainst = "SupportedClaimAgainst",
  SupportedClaimFavor = "SupportedClaimFavor",
  TOPIC = "Topic",
}

enum EdgeLabel {
  converge = "converge",
  diverge = "diverge",
  group = "group",
}

interface GetDocument {
  dataset: string;
  branch: string;
  topic: string;
  document: string;
  timestamp: number;
}

interface DocumentStatusList {
  title: string;
  requiredOptions?: {
    title: string;
    options: string[];
    key: string;
  };
}

export type { GetDocument, DocumentStatusList };
export { Label, EdgeLabel };
