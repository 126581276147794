import React from "react";

// Styles
import { Container, Title, Description } from "./styles";
import colors from "styles/colors";

// Types
import { IArgument } from "types/argument";
import { Tooltip } from "@mui/material";

interface IArgumentComponent extends IArgument {
  notShorted: boolean;
}

const Argument: React.FC<IArgumentComponent> = ({
  title,
  description,
  backgrounColor = colors.white,
  titleColor = colors.black,
  descriptionColor = colors.black,
  notShorted = false,
}) => {
  return (
    <Tooltip
      title={
        notShorted
          ? "Sentence is to long (> 200 chars), it need to be shorted"
          : ""
      }
      placement="top"
    >
      <Container background={backgrounColor}>
        <Title color={titleColor}>{title}</Title>
        <Description color={descriptionColor}>{description}</Description>
      </Container>
    </Tooltip>
  );
};

export default Argument;
