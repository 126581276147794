import { create } from "mobx-persist";
import DashboardStore from "./dashboard";
import UserStore from "./user";

class RootStore {
  private hydrate = create({
    storage: window.localStorage,
    jsonify: true,
  });

  userStore = new UserStore();
  dashboardStore = new DashboardStore();

  constructor() {
    this.hydrate("userStore", this.userStore);
    this.hydrate("dashboardStore", this.dashboardStore);
  }
}

const store = new RootStore();
export { store };
