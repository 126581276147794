import { observable, action, makeAutoObservable } from "mobx";
import { persist } from "mobx-persist";

// Types
import { IUser } from "./types";

export default class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  @observable
  @persist("object")
  user: IUser | undefined = undefined;

  @action
  setUser = (user?: IUser) => {
    this.user = user;
  };
}
