import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Libs
import { useSearchParams } from "react-router-dom";

// Components

// Styles
import "styles/Home.css";

// API
import {
  getDocumentJSONOutlinerStage3API,
  putMergeAPI,
  putNextSourceMergeAPI,
} from "services/api/endpoints/document";

// Services

// Icons

// Colors

// Types
import { IOutlinerData } from "./types";
import _ from "lodash";

const useArgumentOutlinerStage3 = () => {
  const [searchParams] = useSearchParams();

  const [leftTree, setLeftTree] = useState<IOutlinerData[]>([]);
  const [rightTree, setRightTree] = useState<IOutlinerData[]>([]);

  const leftOutlinerTree = useRef();
  const rightOutlinerTree = useRef();

  const [similars, setSimilars] = useState<string[]>([]);
  const [currentSimilar, setCurrentSimilar] = useState(0);

  const [selectedParent, setSelectedParent] = useState<string | undefined>();

  const [selectedText, setSelectedText] = useState<string | undefined>();

  const [selectedLabel, setSelectedLabel] = useState<string | undefined>();

  const [rewritedText, setRewritedText] = useState("");

  const [currentSourceParent, setCurrentSourceParent] = useState<
    string | undefined
  >();
  const [currentSimilarParent, setCurrentSimilarParent] = useState<
    string | undefined
  >();

  const [currentSourceData, setCurrentSourceData] = useState<
    IOutlinerData | undefined
  >();
  const [currentSimilarData, setCurrentSimilarData] = useState<
    IOutlinerData | undefined
  >();

  const [toast, setToast] = useState<{
    isOpen: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  }>({ isOpen: false, message: "", severity: "error" });

  const [backgroundColorNodes, setBackgroundColorNodes] = useState<{
    [label: string]: string;
  }>({});

  const getURLParams = useMemo(() => {
    const branch = searchParams.get("branch");
    const dataset = searchParams.get("dataset");
    const document = searchParams.get("document");
    const timestamp = searchParams.get("timestamp");
    const topic = searchParams.get("topic");

    return {
      branch: branch,
      dataset: dataset,
      document: document,
      timestamp: timestamp,
      topic: topic,
    };
  }, [searchParams]);

  const requestDocumentMetaData = useCallback(
    async ({
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        const {
          data: { options },
        } = await getDocumentJSONOutlinerStage3API({
          dataset,
          branch,
          topic,
          document,
          timestamp,
          only_metadata: true,
        });

        setBackgroundColorNodes({
          ...options.backgroundColorNodes,
          SENTENCETOLONG: "#FFFF00",
        });
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    []
  );

  const requestDocument = useCallback(
    async ({
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        const {
          data: {
            nodes,
            options,
            current_source: _source,
            similars: _similars,
          },
        } = await getDocumentJSONOutlinerStage3API({
          dataset,
          branch,
          topic,
          document,
          timestamp,
          only_metadata: false,
        });

        addIconToNodes({ nodes: [nodes], icons: options.icons });

        const currentSourceData = getNodePathById({
          id: _source,
          nodes: [_.cloneDeep(nodes)],
        });

        if (currentSourceData && currentSourceData.path) {
          const _sourceParent =
            currentSourceData.path[currentSourceData.path.length - 2];

          setCurrentSourceData(currentSourceData.node);
          setCurrentSourceParent(_sourceParent);
          setSelectedParent(_sourceParent);
          setSelectedText(currentSourceData.node?.subtitle);
          setSelectedLabel(currentSourceData.node?.label);
        }

        const currentSimilarData = getNodePathById({
          id: _similars[0],
          nodes: [_.cloneDeep(nodes)],
        });

        if (currentSimilarData && currentSimilarData.path) {
          const _similarParent =
            currentSimilarData.path[currentSimilarData.path.length - 2];

          setCurrentSimilarData(currentSimilarData.node);
          setCurrentSimilarParent(_similarParent);
        }

        const expandedLeftTree = expandTree({
          path: currentSourceData.path || [],
          tree: [_.cloneDeep(nodes)],
        });

        const expandedRightTree = expandTree({
          path: currentSimilarData.path || [],
          tree: [_.cloneDeep(nodes)],
        });

        setSimilars(_similars);
        setLeftTree(expandedLeftTree);
        setRightTree(expandedRightTree);
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const mergeRequest = useCallback(
    async () => {
      try {
        const { branch, dataset, topic, document, timestamp } = getURLParams;

        if (
          branch &&
          dataset &&
          document &&
          topic &&
          timestamp &&
          selectedParent
        ) {
          if (similars.length > 0) {
            await putMergeAPI({
              dataset,
              branch,
              document,
              topic,
              parent: selectedParent,
              target: similars[currentSimilar],
              text:
                selectedText !== "Rewrite text" ? selectedText : rewritedText,
              label: selectedLabel,
            });

            requestDocument({
              branch,
              dataset,
              document,
              timestamp: Number(timestamp),
              topic,
            });
          } else {
            throw new Error("The source do not have similars.");
          }
        } else {
          throw new Error("Inform all params");
        }
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedParent,
      selectedLabel,
      selectedText,
      rewritedText,
      similars,
      currentSimilar,
    ]
  );

  const nextSourceRequest = useCallback(
    async () => {
      try {
        const { branch, dataset, topic, document, timestamp } = getURLParams;

        if (branch && dataset && document && topic && timestamp) {
          await putNextSourceMergeAPI({
            dataset,
            branch,
            document,
            topic,
          });

          requestDocument({
            branch,
            dataset,
            document,
            timestamp: Number(timestamp),
            topic,
          });
        } else {
          throw new Error("Inform all params");
        }
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const addIconToNodes = useCallback(
    ({
      nodes,
      icons,
    }: {
      nodes?: IOutlinerData[];
      icons: { [label: string]: string };
    }): IOutlinerData | undefined => {
      let node = undefined;

      if (nodes)
        for (let i = 0; i < nodes.length; i++) {
          addIconToNodes({ nodes: nodes[i].children, icons });

          nodes[i].icon = icons[nodes[i].label];
        }

      return node;
    },
    []
  );

  const getNodeById = useCallback(
    ({
      id,
      nodes,
    }: {
      id: string;
      nodes?: IOutlinerData[];
    }): IOutlinerData | undefined => {
      let node = undefined;

      if (nodes)
        for (let i = 0; i < nodes.length; i++) {
          node = getNodeById({ id, nodes: nodes[i].children });

          if (node) {
            return node;
          } else if (nodes[i].id === id) {
            return nodes[i];
          }
        }

      return node;
    },
    []
  );

  const getNodePathById = useCallback(
    ({
      id,
      nodes,
    }: {
      id: string;
      nodes?: IOutlinerData[];
    }): { path?: string[]; node?: IOutlinerData } => {
      let path: string[] | undefined = undefined;
      let node: IOutlinerData | undefined = undefined;

      if (nodes)
        for (let i = 0; i < nodes.length; i++) {
          const result = getNodePathById({ id, nodes: nodes[i].children });
          path = result.path;
          node = result.node;

          if (path) {
            return {
              path: [nodes[i].id, ...path],
              node,
            };
          } else if (nodes[i].id === id) {
            return {
              path: [nodes[i].id],
              node: nodes.find((n) => n.id === id),
            };
          }
        }

      return { path };
    },
    []
  );

  const expandTree = useCallback(
    ({
      path,
      tree,
    }: {
      path: string[];
      tree: IOutlinerData[];
    }): IOutlinerData[] => {
      for (let i = 0; i < tree.length; i++) {
        expandTree({ path, tree: tree[i].children || [] });

        const index = path.findIndex((id) => id === tree[i].id);

        if (index >= 0 && index < path.length - 1) {
          tree[i].expanded = true;
        } else if (path.length !== 0 && index === path.length - 1) {
          tree[i].focused = true;
        } else {
          tree[i].expanded = false;
          tree[i].focused = false;
        }
      }

      return tree;
    },
    []
  );

  const nextSimilar = useCallback(() => {
    if (currentSimilar < similars.length - 1) {
      const currentSimilarData = getNodePathById({
        id: similars[currentSimilar + 1],
        nodes: _.cloneDeep(rightTree),
      });

      if (currentSimilarData && currentSimilarData.path) {
        const _similarParent =
          currentSimilarData.path[currentSimilarData.path.length - 2];

        setCurrentSimilarData(currentSimilarData.node);
        setCurrentSimilarParent(_similarParent);
      }

      const expandedRightTree = expandTree({
        path: currentSimilarData.path || [],
        tree: _.cloneDeep(rightTree),
      });

      setCurrentSimilar(currentSimilar + 1);
      setRightTree(expandedRightTree);
    }
  }, [currentSimilar, expandTree, getNodePathById, rightTree, similars]);

  const previousSimilar = useCallback(() => {
    if (currentSimilar > 0) {
      const currentSimilarData = getNodePathById({
        id: similars[currentSimilar - 1],
        nodes: _.cloneDeep(rightTree),
      });

      const expandedRightTree = expandTree({
        path: currentSimilarData.path || [],
        tree: _.cloneDeep(rightTree),
      });

      setCurrentSimilar(currentSimilar - 1);
      setRightTree(expandedRightTree);
    }
  }, [currentSimilar, expandTree, getNodePathById, rightTree, similars]);

  useEffect(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      requestDocumentMetaData({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });

      requestDocument({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Inform all params",
        severity: "error",
      });
    }
  }, [requestDocumentMetaData, requestDocument, getURLParams]);

  return {
    leftTree,
    rightTree,
    currentSourceData,
    currentSimilarData,
    currentSimilarParent,
    currentSourceParent,
    selectedText,
    setSelectedText,
    selectedLabel,
    setSelectedLabel,
    rewritedText,
    setRewritedText,
    selectedParent,
    setSelectedParent,
    nextSimilar,
    previousSimilar,
    nextSourceRequest,
    mergeRequest,
    leftOutlinerTree,
    rightOutlinerTree,
    setLeftTree,
    setRightTree,
    backgroundColorNodes,
    toast,
    setToast,
  };
};

export default useArgumentOutlinerStage3;
