import { api } from "services/api";
import { store } from "store";

// Types
import {
  DocumentAnnotationStatusRequestDTO,
  DocumentAnnotationStatusResponse,
  PutDocumentAnnotationStatusRequestDTO,
  PutDocumentAnnotationStatusResponse,
  DocumentJSONMapRequestDTO,
  DocumentJSONMapResponse,
  DocumentJSONOutlinerResponse,
  DocumentJSONOutlinerRequestDTO,
  PutDocumentAnnotationSilverRequestDTO,
  PutDocumentAnnotationGoldRequestDTO,
  PutDocumentAnnotationSilverResponseDTO,
  PutDocumentAnnotationGoldResponseDTO,
  DocumentJSONOutlinerStage3RequestDTO,
  DocumentJSONOutlinerStage3Response,
  MergeResponse,
  MergeRequestDTO,
  NextSourceMergeRequestDTO,
  NextSourceMergeResponse,
} from "./types";

const getDocumentJSONMapAPI = ({
  dataset,
  branch,
  document,
  topic,
  format,
  timestamp,
  grouped,
  summarized,
}: DocumentJSONMapRequestDTO): Promise<DocumentJSONMapResponse> => {
  const { user } = store.userStore;

  return api.get(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/fmt/${format}/ts/${timestamp}?summarized=${summarized}&grouped=${grouped}`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const getDocumentJSONOutlinerAPI = ({
  dataset,
  branch,
  document,
  topic,
  shorted,
  timestamp,
  grouped,
  byTopic,
  summarized,
  only_metadata,
}: DocumentJSONOutlinerRequestDTO): Promise<DocumentJSONOutlinerResponse> => {
  const { user } = store.userStore;

  const format = byTopic ? "json_topic_outline" : "json_outline";

  return api.get(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/fmt/${format}/ts/0?shorted=${shorted}&summarized=${summarized}&grouped=${grouped}&only_metadata=${
      only_metadata || false
    }`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const getDocumentJSONOutlinerStage3API = ({
  dataset,
  branch,
  document,
  topic,
  only_metadata,
}: DocumentJSONOutlinerStage3RequestDTO): Promise<DocumentJSONOutlinerStage3Response> => {
  const { user } = store.userStore;

  const format = "merged_argument_tree";

  return api.get(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/fmt/${format}/ts/0?&only_metadata=${
      only_metadata || false
    }`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const putMergeAPI = ({
  dataset,
  branch,
  document,
  topic,
  target,
  parent,
  text,
  label,
}: MergeRequestDTO): Promise<MergeResponse> => {
  const { user } = store.userStore;

  // Inserir Variaveis TEXT e LABEL no request

  return api.put(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/merge/${target}/parent/${parent}?text=${text}&label=${label}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const putNextSourceMergeAPI = ({
  dataset,
  branch,
  document,
  topic,
}: NextSourceMergeRequestDTO): Promise<NextSourceMergeResponse> => {
  const { user } = store.userStore;

  return api.put(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/ignore_merge`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const getAnnotationStatusAPI = ({
  dataset,
  branch,
  document,
  topic,
}: DocumentAnnotationStatusRequestDTO): Promise<DocumentAnnotationStatusResponse> => {
  const { user } = store.userStore;

  return api.get(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/status`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const changeAnnotationStatusAPI = ({
  dataset,
  branch,
  document,
  topic,
  status,
  adjudicator_name,
}: PutDocumentAnnotationStatusRequestDTO): Promise<PutDocumentAnnotationStatusResponse> => {
  const { user } = store.userStore;

  return api.put(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/status/${status}?adjudicator_name=${
      adjudicator_name ?? ""
    }`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const changeAnnotationSilverAPI = ({
  dataset,
  branch,
  document,
  topic,
}: PutDocumentAnnotationSilverRequestDTO): Promise<PutDocumentAnnotationSilverResponseDTO> => {
  const { user } = store.userStore;

  return api.put(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/silver_annotation`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const changeAnnotationGoldAPI = ({
  dataset,
  branch,
  document,
  topic,
}: PutDocumentAnnotationGoldRequestDTO): Promise<PutDocumentAnnotationGoldResponseDTO> => {
  const { user } = store.userStore;

  return api.put(
    `ds/${dataset}/br/${branch}/top/${topic}/doc/${document}/base_gold_annotation`,
    {},
    {
      headers: {
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

export {
  getDocumentJSONMapAPI,
  getDocumentJSONOutlinerAPI,
  getDocumentJSONOutlinerStage3API,
  putMergeAPI,
  putNextSourceMergeAPI,
  getAnnotationStatusAPI,
  changeAnnotationStatusAPI,
  changeAnnotationSilverAPI,
  changeAnnotationGoldAPI,
};
