const { REACT_APP_WEBSOCKET_URI } = process.env;

const webSocket = ({
  dataset,
  branch,
  topic,
  document,
  timestamp,
}: {
  dataset: string;
  branch: string;
  topic: string;
  document: string;
  timestamp: number;
}) => {
  const ts = Date.now();
  const client_id =
    `ds;${dataset};br;${branch};top;${topic};doc;${document};ts;${timestamp}` +
    ts;

  const ws = new WebSocket(`${REACT_APP_WEBSOCKET_URI}${client_id}`);

  return ws;
};

export default webSocket;
