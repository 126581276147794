import axios, { AxiosError } from "axios";

// Store
import { store } from "store";
const { REACT_APP_API_BASE_URL } = process.env;

export const { CancelToken, isCancel } = axios;

const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
});

api.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error: AxiosError) {
    const { setUser } = store.userStore;

    if (error.response?.status === 440) {
      setUser();
    }

    return Promise.reject(error);
  }
);

export { api };
