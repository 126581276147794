import React, { useCallback, useEffect, useState } from "react";

// Libs
import SortableTree from "react-sortable-tree";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useResizable } from "react-resizable-layout";
import Carousel from "react-material-ui-carousel";

// Store

// Components
import NodeRendererDefault from "components/OutlinerNode";
import Splitter from "components/Splitter";
import {
  Button,
  FormControlLabel,
  Switch,
  Checkbox,
  AppBar,
  Toolbar,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  IconButton,
} from "@mui/material";
import CloseOutlined from "@mui/icons-material/CloseOutlined";

// View Model
import useArgumentOutliner from "./view.model";

// Styles
import "react-tooltip/dist/react-tooltip.css";
import "./styles.css";

// Colors
import colors from "styles/colors";

// Types
import { IOutlinerData } from "./types";

const cn = (...args: any[]) => args.filter(Boolean).join(" ");

const ArgumentOutliner: React.FC = () => {
  const {
    tree,
    grouped,
    byTopic,
    summarized,
    backgroundColorNodes,
    setTree,
    setGrouped,
    setByTopic,
    setSummarized,
    getURLParams,
    connnectionStatus,
    connect,
    disconnect,
    refresh,
    toast,
    setToast,
    applyTopic,
    setShowDocumentInfo,
    showDocumentInfo,
    docInfo,
  } = useArgumentOutliner();

  const [previousSelectedNode, setPreviousSelectedNode] = useState<
    string | undefined
  >();

  const {
    isDragging: isPluginDragging,
    position: pluginW,
    separatorProps: pluginDragBarProps,
  } = useResizable({
    axis: "x",
    initial: window.innerWidth * 0.7,
    min: 180,
    max: window.innerWidth - 180,
    reverse: true,
  });

  useEffect(() => {
    const keys = Object.keys(backgroundColorNodes || {});

    if (keys && keys.length > 0) {
      for (const key of keys) {
        const element = document?.createElement("style");
        element.innerHTML = `.${key} .rst__rowContents {background-color: ${backgroundColorNodes[key]};}`;
        var header = document.getElementsByTagName("HEAD")[0];
        header.appendChild(element);
      }
    }
  }, [backgroundColorNodes]);

  useEffect(() => {
    window.addEventListener("message", (data) => {
      // console.log(JSON.stringify(data))
    });
  }, []);

  const highlightBratNode = useCallback(
    ({ id }: { id?: string }) => {
      const iFrame: HTMLIFrameElement = document.getElementById(
        "brat-frame"
      ) as HTMLIFrameElement;

      if (
        iFrame.contentWindow &&
        id &&
        id !== "T0" &&
        id !== "AP" &&
        !id.startsWith("G")
      ) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            event: "removeHighlight",
            id: previousSelectedNode,
          }),
          process.env.REACT_APP_EXTERNAL_URL || ""
        );

        iFrame.contentWindow.postMessage(
          JSON.stringify({ event: "highlight", id: id }),
          process.env.REACT_APP_EXTERNAL_URL || ""
        );

        setPreviousSelectedNode(id);
      }
    },
    [previousSelectedNode]
  );

  return (
    <div
      className={
        "flex flex-column h-screen bg-dark font-mono color-white overflow-hidden"
      }
    >
      <AppBar position="static" style={{ background: colors.white }}>
        <Toolbar>
          {byTopic && byTopic.visible && (
            <FormControlLabel
              control={<Checkbox checked={byTopic.default} />}
              label="By Topic"
              onChange={(event, value) =>
                setByTopic({ ...byTopic, default: value })
              }
              style={{ color: colors.black }}
            />
          )}

          {grouped && grouped.visible && (
            <FormControlLabel
              control={<Checkbox checked={grouped.default} />}
              label="Grouped"
              onChange={(event, value) =>
                setGrouped({ ...grouped, default: value })
              }
              style={{ color: colors.black }}
            />
          )}

          {summarized && summarized.visible && (
            <FormControlLabel
              control={<Checkbox checked={summarized.default} />}
              label="Summarized"
              onChange={(event, value) => {
                setSummarized({ ...summarized, default: value });
              }}
              style={{ color: colors.black }}
            />
          )}

          <FormControlLabel
            control={
              <Switch
                checked={connnectionStatus}
                onChange={connnectionStatus ? disconnect : connect}
              />
            }
            label={"Auto refresh"}
            style={{ color: colors.black }}
          />

          <Button
            color="inherit"
            onClick={() => refresh()}
            style={{ color: colors.black }}
          >
            Refresh
          </Button>

          {docInfo && (
            <Button
              color="inherit"
              onClick={() => setShowDocumentInfo(true)}
              style={{ color: colors.black }}
            >
              Document Info
            </Button>
          )}

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{
              color: colors.black,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            ArgMap
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={"flex"}>
        <div
          className={"grow"}
          style={{ overflowX: "hidden", height: window.innerHeight - 70 }}
        >
          <SortableTree
            isVirtualized={false}
            treeData={tree}
            onChange={(_tree: IOutlinerData[]) => {
              setTree(_tree);
            }}
            canDrag={false}
            generateNodeProps={({ node }) => {
              if (
                (node.subtitle &&
                  node.subtitle.length > 200 &&
                  summarized &&
                  summarized.default) ||
                (node.subtitle && node.subtitle.startsWith("Sentence too long"))
              ) {
                node.subtitle =
                  "Sentence too long (limit is 200 chars): necessary to summarize it.";
                return { className: "SENTENCETOLONG" };
              }

              return { className: node.label ? node.label.toUpperCase() : "" };
            }}
            nodeContentRenderer={(data, index) => {
              return (
                <div style={{ height: 60 }}>
                  <NodeRendererDefault
                    {...data}
                    onClick={(title: string) => {
                      highlightBratNode({ id: title });
                      applyTopic({ id: data.node.id });
                    }}
                    icon={data.node.icon}
                  />
                </div>
              );
            }}
          />

          <ReactTooltip id="tooltip" place="bottom" style={{ zIndex: 1 }} />
        </div>
        <Splitter isDragging={isPluginDragging} {...pluginDragBarProps} />
        <div
          className={cn("shrink-0 contents", isPluginDragging && "dragging")}
          style={{ width: pluginW }}
        >
          <iframe
            title="brat-frame"
            id="brat-frame"
            src={`${process.env.REACT_APP_EXTERNAL_URL}brat_client/index.xhtml#/${getURLParams.dataset}/${getURLParams.branch}/${getURLParams.topic}/${getURLParams.document}`}
            height={window.innerHeight - 70}
            width="100%"
          />
        </div>
      </div>

      <Dialog
        open={showDocumentInfo}
        onClose={() => setShowDocumentInfo(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          flexDirection="row"
          alignItems="center"
          display="flex"
          height={40}
        >
          <h3 style={{ flex: 1 }}>Document info</h3>
          <IconButton
            onClick={() => setShowDocumentInfo(false)}
            style={{ width: 40, height: 40 }}
          >
            <CloseOutlined />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {docInfo && (
            <Box noValidate component="form" flexDirection="column">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 15,
                }}
              >
                <DialogContentText alignItems="center">
                  Genre: {docInfo.gender}
                </DialogContentText>
                <Button
                  variant="contained"
                  className="btn"
                  onClick={() => window.open(docInfo.url)}
                  size="large"
                >
                  Go to original web page
                </Button>
              </div>

              <Carousel
                autoPlay={false}
                navButtonsAlwaysVisible
                fullHeightHover
              >
                {docInfo.imgsBase64.map((base64, index) => (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      style={{
                        display: "block",
                        height: window.innerHeight * 0.65,
                      }}
                      id={`${index} base64image`}
                      src={`data:image/png;base64, ${docInfo.imgsBase64[index]}`}
                      alt="Document info screen shot"
                    />
                  </div>
                ))}
              </Carousel>
            </Box>
          )}
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.isOpen}
        onClose={() =>
          setToast({ isOpen: false, message: "", severity: "error" })
        }
      >
        <Alert
          onClose={() =>
            setToast({ isOpen: false, message: "", severity: "error" })
          }
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ArgumentOutliner;
