// Libs
import { MarkerType /*Position*/ } from "react-flow-renderer";
import dagre from "dagre";
import { v4 as uuidv4 } from "uuid";

// Components
import Argument from "components/Argument";
import colors from "styles/colors";

// Types
import { DocumentJSONMapDataDTO } from "services/api/endpoints/document/types";
import { IReactFlowStructure } from "./types";

const position: { x: number; y: number } = { x: 0, y: 0 };

export const backgroundNodes = {
  TOPIC: colors.white,
  CLAIM_FAVOR: colors.success,
  EVIDENCE: colors.success,
  DEFINITION: colors.success,
  EVENT: colors.danger,
};

export const edgeColor = {
  CONVERGE: colors.success,
  DIVERGE: colors.danger,
};

const style = {
  width: 250,
  padding: 0,
};

const _renderContent = ({
  content,
  short,
  summary,
  isSummarized,
}: {
  content: string;
  short: string;
  summary: string;
  isSummarized: boolean;
}): { content: string; showAlert: boolean } => {
  if (isSummarized) {
    if (summary.length > 200) return { content: short, showAlert: true };
    else if (summary.length === 0) return { content, showAlert: false };
    return { content: summary, showAlert: false };
  }

  if (content.length > 200) return { content: short, showAlert: true };
  return { content, showAlert: false };
};

const getReactFlowStructure = ({
  document,
  isSummarized,
  backgroundNodes,
  edgeColors,
}: {
  document: DocumentJSONMapDataDTO;
  isSummarized: boolean;
  backgroundNodes: { [label: string]: string };
  edgeColors: { [label: string]: string };
}): IReactFlowStructure => {
  const formattedDocument: IReactFlowStructure = { nodes: [], edges: [] };
  const direction = "BT"; /*direction = "LR"*/
  const nodeWidth = 250;
  const nodeHeight = 250;

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: direction });

  document.nodes.forEach((node) => {
    const { content, showAlert } = _renderContent({
      content: node.content,
      summary: node.summary,
      short: node.short,
      isSummarized,
    });

    if (node.id) {
      dagreGraph.setNode(node.id, {
        width: nodeWidth,
        height: nodeHeight,
        position,
        style,
      });

      formattedDocument.nodes.push({
        id: node.id,

        data: {
          label: (
            <Argument
              title={`#${node.id} ${node.label}`}
              notShorted={showAlert}
              description={content}
              backgrounColor={
                showAlert
                  ? backgroundNodes["SentenceToLong"]
                  : backgroundNodes[node.label]
              }
            />
          ),
        },
        style,
        position,
        // sourcePosition: Position.Right,
        // targetPosition: Position.Left,
      });
    }
  });

  document.edges.forEach((edge) => {
    if (edge.source && edge.target) {
      dagreGraph.setEdge(edge.source, edge.target);
      formattedDocument.edges.push({
        id: uuidv4(),
        source: edge.target,
        target: edge.source,
        type: "floating",
        markerStart:
          edge.relation !== "GROUP"
            ? {
                type: MarkerType.ArrowClosed,
                height: 30,
                width: 20,
                orient: "auto-start-reverse",
                color: edgeColors[edge.relation],
              }
            : undefined,
        style: { stroke: edgeColors[edge.relation], strokeWidth: 3 },
      });
    }
  });

  dagre.layout(dagreGraph);

  formattedDocument.nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    node.position = {
      x: nodeWithPosition.x,
      y: nodeWithPosition.y,
    };
  });

  return formattedDocument;
};

export { getReactFlowStructure };
