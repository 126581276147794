import { api } from "services/api";

// Types
import { LoginRequestDTO, LoginRequestResponse, PingResponse } from "./types";

// Store
import { store } from "store";

const requestLoginAPI = ({
  userName,
  password,
}: LoginRequestDTO): Promise<LoginRequestResponse> => {
  const formData = new FormData();

  formData.append("username", userName);
  formData.append("password", password);

  return api.post(`cookies`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const pingRequestAPI = (): Promise<PingResponse> => {
  const { user } = store.userStore;
  return api.get("ping", {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

export { requestLoginAPI, pingRequestAPI };
