import { useCallback, useState } from "react";

// Libs
import Cookies from "universal-cookie";

// API
import { requestLoginAPI } from "services/api/endpoints/user";

// Store
import { store } from "store";

const useSignIn = () => {
  const { setUser } = store.userStore;
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [toast, setToast] = useState<{
    isOpen: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  }>({ isOpen: false, message: "", severity: "error" });

  const login = useCallback(async () => {
    try {
      const {
        data: { token, sid, domain, path },
      } = await requestLoginAPI({
        userName,
        password,
      });

      const cookies = new Cookies();
      cookies.set("sid", sid, { domain, path });

      setUser({
        accessToken: token || "",
        userName,
      });
    } catch (error: any) {
      setToast({
        isOpen: true,
        message: error?.response?.data?.detail,
        severity: "error",
      });
    }
  }, [userName, password, setUser]);

  return {
    userName,
    password,
    setUserName,
    setPassword,
    login,
    toast,
    setToast,
  };
};

export default useSignIn;
