import React, { useEffect, useState } from "react";

// Libs
import SortableTree from "react-sortable-tree";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Store

// Components
import NodeRendererDefault from "components/OutlinerNode";
import {
  Button,
  Snackbar,
  Alert,
  Grid,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  TextareaAutosize,
} from "@mui/material";

// View Model
import useArgumentOutlinerStage3 from "./view.model";

// Styles
import "react-tooltip/dist/react-tooltip.css";
import "./styles.css";

// Colors

// Types
import { IOutlinerData } from "./types";

const ArgumentOutlinerStage3: React.FC = () => {
  const [selectParentDialog, setSelectParentDialog] = useState(false);
  const {
    leftTree,
    rightTree,
    currentSourceData,
    currentSimilarData,
    currentSimilarParent,
    currentSourceParent,
    setSelectedText,
    setSelectedLabel,
    rewritedText,
    setRewritedText,
    setSelectedParent,
    nextSimilar,
    previousSimilar,
    nextSourceRequest,
    mergeRequest,
    backgroundColorNodes,
    setLeftTree,
    setRightTree,
    toast,
    setToast,
  } = useArgumentOutlinerStage3();

  useEffect(() => {
    const keys = Object.keys(backgroundColorNodes || {});

    if (keys && keys.length > 0) {
      for (const key of keys) {
        const element = document?.createElement("style");
        element.innerHTML = `.${key} .rst__rowContents {background-color: ${backgroundColorNodes[key]};}`;
        var header = document.getElementsByTagName("HEAD")[0];
        header.appendChild(element);
      }
    }
  }, [backgroundColorNodes]);

  return (
    <div
      className={
        "flex flex-column h-screen bg-dark font-mono color-white overflow-hidden"
      }
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={5} md={5} sm={5}>
            <div
              className={"grow"}
              style={{ overflowX: "hidden", height: window.innerHeight }}
            >
              <SortableTree
                isVirtualized={false}
                treeData={leftTree}
                onChange={(_tree: IOutlinerData[]) => {
                  setLeftTree(_tree);
                }}
                canDrag={false}
                generateNodeProps={({ node }) => {
                  return {
                    className: node.label ? node.label.toUpperCase() : "",
                  };
                }}
                nodeContentRenderer={(data, index) => {
                  return (
                    <div style={{ height: 60 }}>
                      <NodeRendererDefault {...data} icon={data.node.icon} />
                    </div>
                  );
                }}
              />
            </div>
          </Grid>

          <Grid item xs={2} md={2} sm={2}>
            <div className="buttonsContainer">
              <Button variant="contained" className="btn" onClick={nextSimilar}>
                Next Similar
              </Button>
              <Button
                variant="contained"
                className="btn"
                onClick={previousSimilar}
              >
                Previous Similar
              </Button>
              <Button
                variant="contained"
                className="btn"
                onClick={() => {
                  setSelectParentDialog(true);
                }}
              >
                Merge
              </Button>
              <Button
                variant="contained"
                className="btn"
                onClick={nextSourceRequest}
              >
                Next Source
              </Button>
            </div>
          </Grid>

          <Grid item xs={5} md={5} sm={5}>
            <div
              className={"grow"}
              style={{ overflowX: "hidden", height: window.innerHeight }}
            >
              <SortableTree
                isVirtualized={false}
                treeData={rightTree}
                onChange={(_tree: IOutlinerData[]) => {
                  setRightTree(_tree);
                }}
                canDrag={false}
                generateNodeProps={({ node }) => {
                  return {
                    className: node.label ? node.label.toUpperCase() : "",
                  };
                }}
                nodeContentRenderer={(data, index) => {
                  return (
                    <div style={{ height: 60 }}>
                      <NodeRendererDefault {...data} icon={data.node.icon} />
                    </div>
                  );
                }}
              />
            </div>
          </Grid>

          <ReactTooltip id="tooltip" place="bottom" style={{ zIndex: 1 }} />
        </Grid>
      </Box>

      <Dialog
        open={selectParentDialog}
        onClose={() => setSelectParentDialog(false)}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>Merged Node Config</DialogTitle>
        <DialogContent>
          <Box noValidate component="form" flexDirection="column">
            {currentSourceParent !== currentSimilarParent && (
              <FormControl fullWidth>
                <FormLabel>Parent</FormLabel>
                <RadioGroup
                  defaultValue={currentSourceParent}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;

                    const _value = JSON.parse(value);

                    setSelectedParent(_value?.text);
                  }}
                >
                  {[currentSourceParent, currentSimilarParent]?.map(
                    (value, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={value}
                          control={<Radio />}
                          label={value}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </FormControl>
            )}

            {currentSourceData?.label !== currentSimilarData?.label && (
              <FormControl fullWidth>
                <FormLabel>Label</FormLabel>
                <RadioGroup
                  defaultValue={currentSourceData}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;

                    const _value = JSON.parse(value);

                    setSelectedLabel(_value?.text);
                  }}
                >
                  {[currentSourceData, currentSimilarData]?.map(
                    (value, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={value}
                          control={<Radio />}
                          label={`${value?.id}: ${value?.label}`}
                        />
                      );
                    }
                  )}
                </RadioGroup>
              </FormControl>
            )}

            <FormControl fullWidth>
              <FormLabel>Text</FormLabel>
              <RadioGroup
                defaultValue={currentSourceData}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;

                  const _value =
                    value !== "Rewrite text"
                      ? JSON.parse(value)
                      : "Rewrite text";

                  setSelectedText(_value?.text || _value);
                }}
              >
                {[currentSourceData, currentSimilarData, "Rewrite text"]?.map(
                  (value, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={
                          typeof value === "object"
                            ? `${value?.id}: ${value?.subtitle}`
                            : value
                        }
                      />
                    );
                  }
                )}
              </RadioGroup>

              <FormLabel>New Text</FormLabel>
              <TextareaAutosize
                minRows={3}
                value={rewritedText}
                onChange={(event) => {
                  setRewritedText(event.target.value);
                }}
              />
            </FormControl>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                className="btn"
                onClick={() => {
                  mergeRequest();
                  setSelectParentDialog(false);
                }}
                size="large"
              >
                Merge
              </Button>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toast.isOpen}
        autoHideDuration={4000}
        onClose={() =>
          setToast({ isOpen: false, message: "", severity: "error" })
        }
      >
        <Alert
          onClose={() =>
            setToast({ isOpen: false, message: "", severity: "error" })
          }
          severity={toast.severity}
          sx={{ width: "100%" }}
        >
          {toast.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ArgumentOutlinerStage3;
