import { GridSortDirection } from "@mui/x-data-grid";
import { api } from "services/api";
import { store } from "store";

// Types
import {
  DashboardBranchListResponse,
  DashboardRequestResponse,
  DashboardStatusFilterResponse,
  DashboardStageListResponse,
  DashboardStatusListResponse,
  DashboardTopicListResponse,
  DashboardDocIdListResponse,
} from "./types";

const getDashboardAPI = ({
  doc,
  status_filter,
  status,
  topic,
  stage,
  branch,
  sort,
}: {
  doc?: string;
  status_filter?: string;
  status?: string;
  stage?: string;
  topic?: string;
  branch?: string;
  sort: { sort: string; order: GridSortDirection };
}): Promise<DashboardRequestResponse> => {
  const { user } = store.userStore;
  const { offset, limit } = store.dashboardStore;

  const filter = {
    doc,
    status_filter,
    status,
    stage,
    topic,
    branch,
  };

  const { sort: _sort, order } = sort;

  return api.get(
    `ds/argmap1_brat/usr/${
      user?.userName
    }/annotations_info?limit=${limit}&offset=${offset}&filter=${JSON.stringify(
      filter
    )}&sort=${_sort}&order=${order}`,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user?.accessToken}`,
      },
    }
  );
};

const getStatusFilterAPI = (): Promise<DashboardStatusFilterResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/status_filters`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

const getStatusListAPI = (): Promise<DashboardStatusListResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/status_list`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

const getStageListAPI = (): Promise<DashboardStageListResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/stage_list`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

const getTopicListAPI = (): Promise<DashboardTopicListResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/topics_list`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

const getBranchListAPI = (): Promise<DashboardBranchListResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/branches_list`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

const getDocIdListAPI = (): Promise<DashboardDocIdListResponse> => {
  const { user } = store.userStore;

  return api.get(`ds/argmap1_brat/usr/${user?.userName}/doc_ids`, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${user?.accessToken}`,
    },
  });
};

export {
  getDashboardAPI,
  getStatusFilterAPI,
  getStatusListAPI,
  getStageListAPI,
  getTopicListAPI,
  getBranchListAPI,
  getDocIdListAPI,
};
