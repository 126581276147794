import { useCallback, useEffect, useMemo, useRef, useState } from "react";

// Libs
import {
  addEdge,
  useNodesState,
  useEdgesState,
  ConnectionLineType,
  Node,
  Edge,
} from "react-flow-renderer";
import dagre from "dagre";
import { useSearchParams } from "react-router-dom";

// Components
import Argument from "components/Argument";

// Styles
import "styles/Home.css";

// API
import { getDocumentJSONMapAPI } from "services/api/endpoints/document";

// Services
import { getReactFlowStructure } from "services/reactFlow";
import webSocket from "services/webSocket";

// Icons
import { FaTrash, FaPencilAlt } from "react-icons/fa";

// Colors
import colors from "styles/colors";

// Types
import { Label } from "types/document";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const useArgumentMap = () => {
  const [searchParams] = useSearchParams();

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [connnectionStatus, setConnnectionStatus] = useState(false);
  const [grouped, setGrouped] = useState(false);
  const [summarized, setSummarized] = useState(true);

  const [toast, setToast] = useState<{
    isOpen: boolean;
    message: string;
    severity: "error" | "warning" | "info" | "success";
  }>({ isOpen: false, message: "", severity: "error" });

  const wsRef = useRef<WebSocket | undefined>(undefined);

  const getURLParams = useMemo(() => {
    const branch = searchParams.get("branch");
    const dataset = searchParams.get("dataset");
    const document = searchParams.get("document");
    const timestamp = searchParams.get("timestamp");
    const topic = searchParams.get("topic");

    return {
      branch: branch,
      dataset: dataset,
      document: document,
      timestamp: timestamp,
      topic: topic,
    };
  }, [searchParams]);

  const requestDocument = useCallback(
    async ({
      dataset,
      branch,
      topic,
      document,
      timestamp,
    }: {
      dataset: string;
      branch: string;
      topic: string;
      document: string;
      timestamp: number;
    }) => {
      try {
        const response = await getDocumentJSONMapAPI({
          dataset,
          branch,
          topic,
          document,
          format: "json_tree",
          timestamp,
          grouped,
          summarized,
        });

        const { nodes, edges } = getReactFlowStructure({
          document: response.data,
          isSummarized: summarized,
          backgroundNodes: response.data.options.backgroundColorNodes,
          edgeColors: response.data.options.colorEdges,
        });

        setNodes(nodes);
        setEdges(edges);
      } catch (error: any) {
        setToast({
          isOpen: true,
          message: error?.response?.data?.detail,
          severity: "error",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [grouped, summarized]
  );

  const refresh = useCallback(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      requestDocument({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Inform all params",
        severity: "error",
      });
    }
  }, [requestDocument, getURLParams]);

  const connect = useCallback(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      const ws = webSocket({
        dataset,
        branch,
        topic,
        document,
        timestamp: Number(timestamp),
      });

      wsRef.current = ws;

      ws.onmessage = function (event) {
        refresh();
      };

      ws.onopen = function (event) {
        setConnnectionStatus(true);
      };

      ws.onerror = function (event) {
        setConnnectionStatus(false);
        setToast({
          isOpen: true,
          message: "WebSocket Connection error.",
          severity: "error",
        });
      };

      ws.onclose = function (event) {
        setConnnectionStatus(false);
      };
    }
  }, [refresh, getURLParams]);

  const disconnect = useCallback(() => {
    wsRef.current?.close();
  }, []);

  useEffect(() => {
    const { branch, dataset, document, timestamp, topic } = getURLParams;

    if (branch && dataset && document && timestamp && topic) {
      if (!connnectionStatus) {
        connect();
      }

      requestDocument({
        branch,
        dataset,
        document,
        timestamp: Number(timestamp),
        topic,
      });
    } else {
      setToast({
        isOpen: true,
        message: "Inform all params",
        severity: "error",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect, requestDocument, grouped, summarized, getURLParams]);

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [setEdges]
  );

  const editNodeLabel = useCallback(
    (nodeToEdit: Node, label: Label) => {
      const nodesAux = [...nodes];
      const index = nodes.findIndex((node) => node.id === nodeToEdit.id);

      nodesAux[index].data = {
        label: (
          <Argument
            title={`#${nodesAux[index].id} ${label}`}
            notShorted={
              nodesAux[index].data.label.props.description.length > 200
            }
            description={nodesAux[index].data.label.props.description}
            backgrounColor={"white"}
          />
        ),
      };

      setNodes(nodesAux);
    },
    [nodes, setNodes]
  );

  const removeNode = useCallback(
    (nodeToRemove: Node) => {
      const nodesAux: typeof nodes = [];
      const edgesAux: typeof edges = [];

      edges.forEach((edge) => {
        if (edge.target !== nodeToRemove.id) {
          edgesAux.push(edge);
        }
      });

      nodes.forEach((node) => {
        if (node.id !== nodeToRemove.id) {
          nodesAux.push(node);
        }
      });

      setEdges(edgesAux);
      setNodes(nodesAux);
    },
    [edges, nodes, setEdges, setNodes]
  );

  const removeEdge = useCallback(
    (edgeToRemove: Edge) => {
      const edgesAux: typeof edges = [];

      edges.forEach((edge) => {
        if (edge.id !== edgeToRemove.id) {
          edgesAux.push(edge);
        }
      });

      setEdges(edgesAux);
    },
    [edges, setEdges]
  );

  const nodeOptions = useMemo(
    () => [
      {
        name: "Edit label",
        icon: <FaPencilAlt color={colors.primary} />,
      },
      {
        name: "Delete",
        icon: <FaTrash color={colors.danger} />,
        action: (node: Node) => removeNode(node),
      },
    ],
    [removeNode]
  );

  return {
    nodes,
    edges,
    grouped,
    setGrouped,
    summarized,
    setSummarized,
    nodeOptions,
    onNodesChange,
    onEdgesChange,
    onConnect,
    connnectionStatus,
    connect,
    disconnect,
    refresh,
    removeNode,
    editNodeLabel,
    removeEdge,
    toast,
    setToast,
  };
};

export default useArgumentMap;
